import * as React from "react";
import { graphql } from "gatsby"
import 'bootstrap/scss/bootstrap.scss'
import "../scss/global.scss";
import "../scss/grunticon.scss"
import "slick-carousel/slick/slick.css";
import "../scss/custom.scss";
import Layout from "../components/layout"
import Modules from "../components/modules"
import SEO  from "../components/seo"
import "../../src/components/StaticComponent/StaticComponent.scss"

// markup
const DefaultTemplate = (props) => {

  const GQLPage = props.data?.glstrapi?.page
  const GQLModules = GQLPage?.add_components
  // const locationState = props.location.state

  var layout = GQLPage?.layout

    return (
      <div className={`${layout == "homepage_template" ? '' : layout == "landing_template"  || layout == "form_template" || layout == "guide_template" ? 'land-header' : 'white-header'} ${GQLPage.page_class ? GQLPage.page_class : ''} ${layout == "form_template" ? 'form-full-image-page' : ''} ${layout == "static_template" ? 'form-full-image-page-static' : ''}`}>
        <SEO title={GQLPage?.seo_meta?.title} description={GQLPage?.seo_meta?.description}/>
        <Layout>
            <Modules {...GQLPage}/>
        </Layout>
      </div>
    )
}
  
export default DefaultTemplate
  
export const pageQuery = graphql`
  query GetArticle($id: ID!) {
    glstrapi {
      page(id: $id, publicationState: LIVE) {
        alias
        banner_content
        banner_cta_label
        banner_right_content
        banner_search_default_select
        choose_form
        content
        layout
        page_class
        show_reviews
        show_search
        title
        imagetransforms
        id
        seo_meta {
          title
          description
          keywords
          image {
            url
          }
        }
        menu {
          label
          id
        }
        banner_image {
          url
          alternativeText
          url_sharp {
            childImageSharp {
              gatsbyImageData(
                formats: WEBP
                width: 1800
                quality: 100
                layout: CONSTRAINED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              ) 
            }
          }
        }
        banner_cta_link {
          id
          slug
        }
        add_components {
          __typename
          ... on GLSTRAPI_ComponentComponentsSelectCollection {
            choose_collection
            title
            description
          }
          ... on GLSTRAPI_ComponentComponentsIntroSection {
            text_block {
              title
              description
              Main_Section_Title
            }
            link {
              id
              label
              menu_item {
                id
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsShowCustomerReviews {
            show
          }
          ... on GLSTRAPI_ComponentComponentsTextBlockWithImage {            
            layout_type
            Button_CTA {
              label
              menu_item {
                id
              }
            }
            media_block {
              Video_Url
              media_file {
                url
                alternativeText
              }
            }
            text_block {
              description
              title
            }
          }
          ... on GLSTRAPI_ComponentComponentsFullWidthTwoColumnBlock {
            column_1_cta_label
            column_1_sub_title
            column_1_title
            column_2_sub_title
            column_2_title
            column_1_cta_link {
              id
            }
            column_2_cta_label
            column_2_cta_link {
              id
            }
          }
          ... on GLSTRAPI_ComponentComponentsShowFeaturedProperty {
            show
          }
          ... on GLSTRAPI_ComponentComponentsShowModule {
            id
            module {
              module_type
              section_name
              add_new_text_with_image_module {
                layout_type
                text_block {
                  description
                  title
                }
                media_block {
                  media_file {
                    url
                    alternativeText
                    url_sharp {
                      childImageSharp {
                        gatsbyImageData(
                          formats: WEBP
                          width: 500
                          quality: 100
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER, fit: COVER }
                        ) 
                      }
                    }
                  }
                }
                Button_CTA {
                  label
                  menu_item {
                    id
                  }
                  Secondary_Url
                }
              }
              add_new_stats_or_service {
                description
                icon_class_name
                title
                properties_count
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsShowMeetTeam {
            show
            title
            select_peoples {
              email
              designation
              name
              mobile_no
              slug
              id
              imagetransforms
              tile_image {
                alternativeText
                url
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsContactPageInfo {
            Intro
            contact_form_intro
            show_contact_details
          }
          ... on GLSTRAPI_ComponentComponentsListAPropertyForm {
            show_form
          }
          ... on GLSTRAPI_ComponentComponentsFaq {
            _id
            faq_intro
            choose_faqs {
              Answer
              Question
            }
          }
          ... on GLSTRAPI_ComponentComponentsAddVideoReview {
            add_video {
              title
              video_url
              content
              image {
                alternativeText
                url
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsQuickLinks {
            Title
            Add_Link {
              Tile_Link_Label
              Tile_Title
              Link {
                id
              }
              Tile_Image {
                url
                alternativeText
                url_sharp {
                  childrenImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 320
                      quality: 100
                      layout: CONSTRAINED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    ) 
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsContentWithPoints {
            Add_Content {
              Content
              Title
            }
          }
        }
      }
    }
  }
`